const initNavbar = () => {
  document.addEventListener('DOMContentLoaded', () => {
    const $navbarBurger = document.querySelector('.navbar-burger');
    const $navbar = document.querySelector('.navbar');

    // Check if there are any navbar burgers
    if ($navbarBurger) {
      $navbarBurger.addEventListener('click', () => {
        // Get the target from the "data-target" attribute
        const { target } = $navbarBurger.dataset;
        const $target = document.getElementById(target);

        // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
        $navbarBurger.classList.toggle('is-active');
        $navbar.classList.toggle('is-active');
        $target.classList.toggle('is-active');
      });
    }
  });
};

initNavbar();
