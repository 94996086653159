const throttle = (fnc, timeout) => {
  let canCall = true;
  let args;
  let hasBeenCalledDuringTimeout = false;

  const throttledFnc = (...currentArgs) => {
    args = currentArgs;
    if (canCall) {
      canCall = false;
      hasBeenCalledDuringTimeout = false;
      fnc(...args);
      setTimeout(() => {
        canCall = true;
        if (hasBeenCalledDuringTimeout) throttledFnc(...args);
      }, timeout);
    } else {
      hasBeenCalledDuringTimeout = true;
    }
  };

  return throttledFnc;
};

export default throttle;
