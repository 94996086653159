import { gsap } from '../../_snowpack/pkg/gsap.js';
import { ScrollToPlugin } from '../../_snowpack/pkg/gsap/ScrollToPlugin.js';

gsap.registerPlugin(ScrollToPlugin);

const initScroller = () => {
  document.addEventListener('DOMContentLoaded', () => {
    const $navbarItems = Array.prototype.slice
      .call(document.querySelectorAll('.go-to-anchor-link'), 0);

    if ($navbarItems.length > 0) {
      $navbarItems.forEach((el) => {
        el.addEventListener('click', (e) => {
          e.preventDefault();
          const targetId = el.getAttribute('href');
          if (targetId && targetId.startsWith('#')) {
            gsap.to(window, {
              duration: 0.05,
              scrollTo: {
                y: targetId,
                offsetY: 82,
              },
            });
          }
          return false;
        });
      });
    }
  });
};

initScroller();
