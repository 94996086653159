import throttle from './throttler.js';

const listenScroll = () => {
  const $body = document.querySelector('body');
  const $navbarBurger = document.querySelector('.navbar-burger');
  const $navbar = document.querySelector('.navbar');
  const { target } = $navbarBurger.dataset;
  const $navbarBurgerTarget = document.getElementById(target);
  document.addEventListener('scroll', throttle(() => {
    const y = window.scrollY;
    if ($body) {
      if (y > 300) {
        $body.classList.add('is-scrolled');
      } else {
        $body.classList.remove('is-scrolled');
      }
    }
    if ($navbarBurger && y > 0) {
      $navbarBurger.classList.remove('is-active');
      $navbarBurgerTarget.classList.remove('is-active');
      $navbar.classList.remove('is-active');
    }
  }, 300));
};

listenScroll();
