import BigPicture from '../../_snowpack/pkg/bigpicture.js';

const initGallery = () => {
  const imgs = Array.prototype.slice.call(
    document.querySelectorAll('.gallery__figure > img'),
    0,
  );

  imgs.forEach((el, i) => {
    el.addEventListener(
      'click',
      (e) => {
        BigPicture({
          el: e.target,
          gallery: imgs,
          galleryAttribute: 'src',
          position: i,
        });
        return false;
      },
    );
  });
};

initGallery();
